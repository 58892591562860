import React from "react";
import PropTypes from "prop-types";
import Select from "../../shared/InputElements/Select";
import { useDispatch, useSelector } from "react-redux";
import { getProvider } from "../../../redux/selector";
import { updateProvider } from "../../../redux/Slices/userSlice";

const SelectElectricityProvider = ({
  id = "electricity-provider",
  location,
  ...rest
}) => {
  const dispatch = useDispatch()
  const provider = useSelector(getProvider)

  const updateElectricityProvider = (provider) => {
    dispatch(updateProvider(provider))
  }

  let power_suppliers = [];
  if (location) {
        location.power_supplier.forEach((spot) => {
                power_suppliers.push(spot.name);
        });
  }

  return (
    <Select
      id={id}
      isSpecial={true}
      value={provider}
      // label="Select Electricity Provider"
      optionNames={power_suppliers.length >= 1 ? ['-', ...power_suppliers] : ["None"]}
      optionValues={power_suppliers.length >= 1 ? ['', ...power_suppliers] : ["None"]}
       handler={(e) => updateElectricityProvider(e.target.value)}
       ariaLabel="Select Electricity Provider"
    />
  );
};

export default SelectElectricityProvider;

SelectElectricityProvider.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
};
