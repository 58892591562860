import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './IncentiveCard.scss';
import { FormatAsDollars } from './../../utils/Helpers/Format';
import GaTracker from './../../utils/GaTracker/GaTracker';
import { useIntl } from 'react-intl';

const IncentiveCard = (props) => {
  const intl = useIntl();

  const [showMore, setShowMore] = useState(false);
  let incentive = props && props.incentive ? props.incentive : {};
  let eligibility = incentive.evaluation && incentive.evaluation.eligibility;

  if (eligibility === 'ineligible') {
    return null;
  }

  let incentiveTypes = {
    'Bill Credit': intl.formatMessage
      ? intl.formatMessage({ id: 'billCredit', defaultMessage: 'Bill Credit' })
      : 'Bill Credit',
    'Discounted Electric Rate': intl.formatMessage
      ? intl.formatMessage({
          id: 'discountedElectricRate',
          defaultMessage: 'Discounted Electric Rate',
        })
      : 'Discounted Electric Rate',
    Financing: intl.formatMessage
      ? intl.formatMessage({ id: 'financing', defaultMessage: 'Financing' })
      : 'Financing',
    'Free Equipment': intl.formatMessage
      ? intl.formatMessage({
          id: 'freeEquipment',
          defaultMessage: 'Free Equipment',
        })
      : 'Free Equipment',
    'HOV Lane': intl.formatMessage
      ? intl.formatMessage({ id: 'hovLane', defaultMessage: 'HOV Lane' })
      : 'HOV Lane',
    'Insurance Discount': intl.formatMessage
      ? intl.formatMessage({
          id: 'insuranceDiscount',
          defaultMessage: 'Insurance Discount',
        })
      : 'Insurance Discount',
    'Parking Exemption': intl.formatMessage
      ? intl.formatMessage({
          id: 'parkingExemption',
          defaultMessage: 'Parking Exemption',
        })
      : 'Parking Exemption',
    'Public Charging': intl.formatMessage
      ? intl.formatMessage({
          id: 'publicCharging',
          defaultMessage: 'Public Charging',
        })
      : 'Public Charging',
    Rebate: intl.formatMessage
      ? intl.formatMessage({ id: 'rebate', defaultMessage: 'Rebate' })
      : 'Rebate',
    'Tax Credit': intl.formatMessage
      ? intl.formatMessage({ id: 'taxCredit', defaultMessage: 'Tax Credit' })
      : 'Tax Credit',
    'Tax Exemption': intl.formatMessage
      ? intl.formatMessage({
          id: 'taxExemption',
          defaultMessage: 'Tax Exemption',
        })
      : 'Tax Exemption',
    'Toll Discount': intl.formatMessage
      ? intl.formatMessage({
          id: 'tollDiscount',
          defaultMessage: 'Toll Discount',
        })
      : 'Toll Discount',
      'Vehicle Retirement': intl.formatMessage
      ? intl.formatMessage({
          id: 'vehicleRetirement',
          defaultMessage: 'vehicle Retirement',
        })
      : 'Vehicle Retirement',
  };

  let incentiveType = incentiveTypes[incentive.type] || incentive.type || '';
  let description = incentive.description || '';

  let amountDescription =
  incentive.typical_amount && incentive.typical_amount !== 'N/A'
    ? incentive.typical_amount.replace('-$', '-')
    : '';

    const getAmount = () => {
      if (!incentive.evaluation) return amountDescription;
      if (incentive.support_for !== 'Vehicle purchase') return amountDescription;
    
      const { applicable_to_lease, applicable_to_ownership, amount_in_purchase, amount_in_lease } = incentive.evaluation;
    
      if (applicable_to_lease && applicable_to_ownership) {
        return amount_in_purchase > 0 ? FormatAsDollars(amount_in_purchase) : 
               amount_in_lease > 0 ? FormatAsDollars(amount_in_lease) : 'bolt';
      }
    
      if (applicable_to_lease && !applicable_to_ownership) {
        return FormatAsDollars(amount_in_lease);
      }
    
      if (!applicable_to_lease && applicable_to_ownership) {
        return FormatAsDollars(amount_in_purchase);
      }
    
      return amountDescription; 
    }

  const handleShowClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowMore((s) => !s);
  };

  const handleClick = () => {
    GaTracker.trackEvent({
      category: 'Incentives',
      action: 'Clicked on Incentive Card',
      label: `${incentive.name}`,
    });
  };

  let renderCard =
    incentive && !props.displayNone ? (
      <a
        className={`${props.displayNone ? 'displayNone' : 'IncentiveCard'} ${
          showMore ? 'showAllCard' : ''
        }`}
        href={incentive.details_url}
        onClick={() => handleClick()}
        data-incentive-id={incentive.id}
        target='_blank'
        rel="noopener noreferrer"
      >
        <div className={showMore ? `displayNone` : `IncentiveCardTop`}>
          <span>{incentive.grantor + ' - ' + incentiveType}</span>
        </div>
        <div className={showMore ? `displayNone` : `IncentiveCardBody`}>
          <p className="h1 card-title">
            {getAmount() && getAmount() !== 'bolt' ? (
              getAmount()
            ) : (
              <svg
                width="34"
                height="55"
                viewBox="0 0 34 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.880803 54.143C0.483925 54.0273 0.189101 53.8063 0.0870463 53.4065C0.0530281 53.2802 0.0416877 53.175 0.0983846 53.0592C0.529281 52.1017 0.948839 51.1442 1.39108 50.1867C4.29396 43.7997 7.1855 37.4022 10.0884 31.0152C10.3719 30.3944 10.644 29.7736 10.9275 29.1528C10.9502 29.0896 10.9955 29.037 10.9842 28.9528C10.8935 28.9528 10.7914 28.9528 10.7007 28.9528C7.52568 28.9528 4.35065 28.9528 1.17563 28.9528C0.585978 28.9528 0.279816 28.7319 0.0870463 28.2163C-0.00366879 27.9743 0.121064 27.7744 0.223118 27.585C1.01688 26.1013 1.82197 24.6072 2.62707 23.1235C6.61853 15.7054 10.6213 8.28721 14.6128 0.869051C14.7942 0.53234 15.0323 0.269285 15.4292 0.164062C20.7134 0.164062 25.9975 0.164062 31.2817 0.164062C31.293 0.174585 31.3044 0.195628 31.3157 0.195628C31.9847 0.311372 32.2569 1.06897 31.86 1.65822C28.1407 7.08768 24.4327 12.5171 20.7247 17.9466C20.6907 17.9992 20.6567 18.0623 20.6113 18.136C20.7134 18.1781 20.8154 18.1676 20.9061 18.1676C24.5914 18.1676 28.2767 18.1676 31.962 18.1676C32.4837 18.1676 32.8465 18.378 32.9939 18.7673C33.1187 19.0935 33.0506 19.3881 32.8238 19.6512C31.1002 21.5557 29.3767 23.4602 27.6417 25.3648C25.7821 27.4166 23.9224 29.4684 22.0514 31.5203C20.3732 33.3722 18.695 35.2241 17.0054 37.076C15.3272 38.9279 13.6489 40.7798 11.9707 42.6317C10.3719 44.3995 8.76167 46.1567 7.16282 47.9244C5.40521 49.8605 3.64761 51.7966 1.89001 53.7432C1.70858 53.9431 1.49313 54.0799 1.23232 54.1641C1.10759 54.143 0.994197 54.143 0.880803 54.143Z"
                  fill="#AEAEAE"
                />
              </svg>
            )}
          </p>
          <p className="h2 light">{incentive.name || ''}</p>
        </div>
        {showMore ? (
          <div className="IncentiveCardDetails">
            <p className="h3">{showMore ? description : null}</p>
          </div>
        ) : null}

        <div
          className={
            showMore ? 'IncentiveCardBottom active' : 'IncentiveCardBottom'
          }
        >
          <div className="IncentiveCardButtonLinks">
            <a 
              href={incentive.details_url}
              target='_blank'
              rel="noopener noreferrer"
            >
              APPLY NOW
            </a>
            <button
              className="showBtn"
              onClick={handleShowClick}
              style={{ alignSelf: 'self-start' }}
            >
              {showMore ? (
                <span className="readFull">FLIP BACK</span>
              ) : (
                <span className="readFull">SEE MORE</span>
              )}
            </button>
          </div>
        </div>
      </a>
    ) : null;

  return renderCard;
};

export default IncentiveCard;

IncentiveCard.propTypes = {
  incentive: PropTypes.object,
  compact: PropTypes.bool,
};
