
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import IncentiveCatalog from './../../components/IncentiveCatalog/IncentiveCatalog';
import IncentiveScoreOptions from '../../client_customizations/components/IncentiveScoreOptions/IncentiveScoreOptions';
import LoadingSpinner from './../../components/LoadingSpinner/LoadingSpinner';
import { FormatAsDollars } from './../../utils/Helpers/Format';
import ModalComponent from '../../components/ModalUIComponent/ModalUIComponent';
import { useWidget } from "../../context/widgetContext";
import './Incentives.scss';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { getProvider } from '../../redux/selector';

const Incentives = () => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const handleModalUpdate = (value) => setShowModal(value ? value : !showModal);
  const { electricVehicles, incentives, userLocation } = useWidget();
  const provider = useSelector(getProvider)

  const filterIncentives = useMemo(() => {
    if (!incentives) return [];
  
    return incentives.filter(incentive => {
      const isAvailable = incentive.available;
      const isFederalAndAvailable = isAvailable && incentive?.grantor === "Federal";
      const isEligible = incentive.evaluation ? incentive?.evaluation.eligibility === 'eligible' : true;
      const isRegionalAndAvailable = isAvailable && incentive?.grantor === userLocation?.region;

      return isAvailable && (
        isFederalAndAvailable || 
        (isEligible && (!provider || incentive.grantor === provider)) ||
        isRegionalAndAvailable
      );
  
    });
  }, [incentives, provider, userLocation]);


  const getIncentiveSum = () => {
      let sum = 0;
      if (!filterIncentives) return FormatAsDollars(sum);

      filterIncentives.forEach((incentive) => {
        if (incentive.evaluation) {
            const amountInPurchase = incentive.evaluation.amount_in_purchase;
            const amountInLease = incentive.evaluation.amount_in_lease;
            if (amountInPurchase && amountInPurchase !== 0) {
                sum += amountInPurchase;
            } else {
                sum += amountInLease;
            }
        } else {
            sum += incentive.max_amount;
        }
      });

      return FormatAsDollars(sum);
  };

  const renderIncentives = filterIncentives ? (
    <IncentiveCatalog incentives={filterIncentives} category="all" />
  ) : (
    <LoadingSpinner />
  );

  const renderOptions = (
    <>
      {window.innerWidth > 720 ? (
        <IncentiveScoreOptions
          userLocation={userLocation}
          electricVehicles={electricVehicles}
          onFormSubmitted={handleModalUpdate}
        />
      ) : (
        <ModalComponent
          isOpen={showModal}
          onStatusChanged={handleModalUpdate}
          buttonText={
            intl.formatMessage
              ? intl.formatMessage({
                  id: 'applyFilters',
                  defaultMessage: 'ADJUST FILTERS',
                })
              : 'ADJUST FILTERS'
          }
          submitText={
            intl.formatMessage
              ? intl.formatMessage({
                  id: 'applyFilters',
                  defaultMessage: 'APPLY FILTERS',
                })
              : 'APPLY FILTERS'
          }
          disclaimerText={
            <FormattedMessage
              id="ev.electricityRateCalc"
              defaultMessage="Electricity rate is calculated as the average for six month of summer off-peak and six months off-peak for Rate A, the cheapest rate available for your zip code."
              description="Vehicles Disclaimer"
            />
          }
        >
          <div className="filters-container">
            <IncentiveScoreOptions
              userLocation={userLocation}
              electricVehicles={electricVehicles}
              onFormSubmitted={handleModalUpdate}
              isModal={true}
            />
          </div>
        </ModalComponent>
      )}
    </>
  );

  return (
    <section className="container-fluid" id="incentives">
      <div className="container">
        <div className="filters-container">{renderOptions}</div>
        <div className="incentives-container">
          <h2>
            YOU COULD QUALIFY FOR SAVINGS UP TO{' '}
            <strong>{getIncentiveSum()}</strong>, INCLUSIVE OF VEHICLE TAX CREDITS AND REBATES
          </h2>
          {renderIncentives}
        </div>
      </div>
    </section>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
