export const getZipcode = (state) => state.user.zipcode
export const getActiveVehicle = (state) => state.user.activeVehicle
export const getActiveTrim = (state) => state.user.activeTrim
export const getProvider = (state) => state.user.electricityProvider
export const getHouseholdIncome = (state) => state.user.householdIncome
export const getHouseholdSize = (state) => state.user.householdSize
export const getTaxFilingStatus = (state) => state.user.taxFilingStatus
export const getTradeInVehicle = (state) => state.user.tradeInVehicle
export const getFullVehicle = (state) => state.user.vehicles

export const getAnnualMiles = (state) => state.user.annualMileage
export const getEquivalentGasMPG = (state) => state.user.equivalentGasMPG
export const getElectricityPrice = (state) => state.user.electricityPrice
export const getFuelPrice = (state) => state.user.fuelPrice
export const getPercentElectric = (state) => state.user.percentElectric
export const getActivePurchaseMethod = (state) => state.user.activePurchaseMethod
export const getTrims = (state) => state.user.trims