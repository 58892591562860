import { createSlice } from '@reduxjs/toolkit'
import queryString from 'query-string';

const query = queryString.parse(window.location.search);

const initialProvider = query.electricity_provider
    ? query.electricity_provider === 'PG' ? 'PG&E' : query.electricity_provider
    : 'PG&E'

const initialTradeIn = query.tradein_vehicle 
    ? query.tradein_vehicle === 'no' ? 'false' : 'true'
    : 'false'

const initialTrim = query.vehicle_model ? query.trims ?? '' : '450h+ PHEV';


const initialState = {
  zipcode: query.zipcode ?? 92708,
  activeVehicle: query.vehicle_model ?? 'NX',
  activeTrim: initialTrim,
  electricityProvider: initialProvider,
  householdIncome: query.household_income ?? 50000,
  householdSize: query.household_size ?? 2,
  taxFilingStatus: query.tax_filing_type ?? 'single',
  tradeInVehicle: initialTradeIn,
  annualMileage: query.miles_driven_per_year ?? 15000,
  percentElectric: query.electric_miles_driven ?? 70,
  equivalentGasMPG: query.comparison_MPG ?? 30,

  electricityPrice: 0.45,
  fuelPrice: 3.25,
  numYears: 5,
  trims: [],
  vehicles: [],
  activePurchaseMethod: 'lease',
};


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateZipcode: (state, action) => {
            state.zipcode = action.payload
        },
        updateActiveVehicle: (state, action) => {
            state.activeVehicle = action.payload
        },
        updateActiveTrim: (state, action) => {
            state.activeTrim = action.payload
        },
        updateAnnualMileage: (state, action) => {
            state.annualMileage = action.payload
        },
        updateEquivalentGasMPG: (state, action) => {
            state.equivalentGasMPG = action.payload
        },
        updateElectricityPrice: (state, action) => {
            state.electricityPrice = action.payload
        },
        updateFuelPrice:(state, action) => {
            state.fuelPrice = action.payload
        },
        updatePercentElectric: (state, action) => {
            state.percentElectric = action.payload
        },
        updateTrims: (state, action) => {
            state.trims = action.payload
        },
        updateVehicles: (state, action) => {
            state.vehicles = action.payload
        },
        updateActivePurchaseMethod: (state, action) => {
            state.activePurchaseMethod = action.payload
        },

        updateProvider: (state, action) => {
            state.electricityProvider = action.payload
        },
        updateIncome: (state, action) => {
            state.householdIncome = action.payload
        },
        updateSize: (state, action) => {
            state.householdSize = action.payload
        },
        updateFilingStatus: (state, action) => {
            state.taxFilingStatus = action.payload
        },
        updateTradeIn: (state, action) => {
            state.tradeInVehicle = action.payload
        },
    }
})

export const {
    updateZipcode,
    updateActiveVehicle,
    updateActiveTrim,
    updateAnnualMileage,
    updateEquivalentGasMPG,
    updateElectricityPrice,
    updateFuelPrice,
    updatePercentElectric,
    updateTrims,
    updateMaintenance,
    updateVehicles,
    updateActivePurchaseMethod,
    updateProvider,
    updateIncome,
    updateSize,
    updateFilingStatus,
    updateTradeIn
} = userSlice.actions

export default userSlice.reducer
