import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import GaTracker from '../../utils/GaTracker/GaTracker';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalComponent = ({
  children,
  submitText,
  buttonText,
  titleText,
  isEmissions,
  isOpen = false,
  onStatusChanged = undefined,
}) => {
  const [showModal, setModal] = useState(isOpen);
  const toggle = () => {
    const newState = !showModal;
    setModal(newState);
    if (onStatusChanged) onStatusChanged(newState);
    // GaTracker.trackEvent({
    //   event: 'zr-click-event',
    //   category: 'Lexus Adjust Filters Button',
    //   eventName: 'button',
    //   label:
    //     'Lexus Electric Vehicle and Charger Incentives:Incentive Page:Adjust Filters',
    // });
  };
  useEffect(() => {
    if (isOpen === showModal) return;
    setModal(isOpen);
  }, [isOpen, showModal]);

  let renderFooter = submitText ? (
    <ModalFooter>
      <Button color="btn btn-lexus" onClick={toggle}>
        {submitText}
      </Button>
    </ModalFooter>
  ) : null;

  return (
    <div style={isEmissions ? {width: "100%", marginBottom: "50px"} : {}}>
      <button onClick={toggle} className="btn-lexus" type="button">
        {buttonText}
      </button>
      <Modal isOpen={showModal} toggle={toggle}>
        <ModalHeader className="modalHeader">
          <div className="titleText">{titleText}</div>
          <Button className="closeButton" onClick={() => toggle(false)}>
            &#x2715;
          </Button>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        {renderFooter}
      </Modal>
    </div>
  );
};

export default ModalComponent;

ModalComponent.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
  titleText: PropTypes.string,
  submitText: PropTypes.string,
};
