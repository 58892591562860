import React from "react";

import Range from "../../../components/shared/InputElements/Range";
import ToolTip from '../../../components/shared/ToolTip/ToolTip';
import { getEquivalentGasMPG } from "../../../redux/selector"
import { useDispatch, useSelector } from 'react-redux';
import { updateEquivalentGasMPG } from "../../../redux/Slices/userSlice";

const descriptionFn = val => {
       return val;
};

const SlideEquivalentGasMPG = ({
       type,
       description = descriptionFn
}) => {
    const dispatch = useDispatch()
    const equivalentGasMPG = useSelector(getEquivalentGasMPG)
    const maxNumber = 80;

    const updateEquivalentGasEfficiency = (mpg) => {
        if (!isNaN(Number(mpg))) {
            if (mpg > 80) {
                dispatch(updateEquivalentGasMPG(Number(maxNumber)))
            } else {
                dispatch(updateEquivalentGasMPG(Number(mpg)))
            }
        }
    }

    return (
        <div className="input-group-container">
              <div className="incentive-label-select">
                     <label htmlFor="comparisonMPG">COMPARISON MPG</label>
                     <ToolTip message={"Estimate the combined MPG of the gas-only vehicle this EV will be replacing."} id={"slide-mpg-tooltip"} />
              </div>
              <Range
                     id="comparisonMPG"
                     value={equivalentGasMPG}
                     description={description}
                     ariaLabel="Comparison MPG"
                     rangeMin={5}
                     rangeMax={maxNumber}
                     rangeStep={5}
                     handler={e => updateEquivalentGasEfficiency(e.target.value)}
              />
       </div>
    )
}

export default SlideEquivalentGasMPG
