/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react';
import fetchElectricVehicles from '../services/fetchElectricVehicles';

const useElectricVehicleData = (params, language) => {
    const [electricVehicles, setElectricVehicles] = useState([]);
    const [usedElectricVehicles, setUsedElectricVehicles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { postcode, income, size} = params

    useEffect(() => {
      const loadElectricVehicleData = async () => {
        setIsLoading(true);
        try {
          let params = {
            postcode:  postcode,
            household_size:  size,
            household_income:  income,
            lang: language === 'EN' ? null : language,
          };
          
          const response = await fetchElectricVehicles(params);
          if (!response) {
            setError('No data returned');
            return;
          }
  
          setElectricVehicles(response.newElectricVehicles || []);
          setUsedElectricVehicles(response.usedElectricVehicles || []);
        } catch (e) {
          setError(e.toString());
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      };
  
      loadElectricVehicleData();
    }, [params, language]);
  
    return { electricVehicles, usedElectricVehicles, isLoading, error };
  };
  
  export default useElectricVehicleData;