import React from "react";

import Range from "../../../components/shared/InputElements/Range";
import ToolTip from '../../../components/shared/ToolTip/ToolTip';
import { useDispatch, useSelector } from 'react-redux';
import { updateAnnualMileage } from "../../../redux/Slices/userSlice";
import { getAnnualMiles } from "../../../redux/selector"
import { FormatAsThousands } from "../../../utils/Helpers/Format";

const descriptionFn = val => {
       return FormatAsThousands(val) + ' miles';
};

const SelectAnnualMileage = ({
       description = descriptionFn
}) => {
    const dispatch = useDispatch()
    const annualMiles = useSelector(getAnnualMiles)

    const updateAnnualMiles = (miles) => {
        dispatch(updateAnnualMileage(Number(miles)))
    }

    return (
       <div className="input-group-container">
              <div className="incentive-label-select">
                     <label htmlFor="annualMileage">MILES DRIVEN PER YEAR</label>
                     <ToolTip message={"Estimate the number of miles you drive each year."} id={"slide-percent-annual-mileage-tooltip"} />
              </div>
              <Range
                     value={annualMiles}
                     description={description}
                     ariaLabel="Annual Mileage"
                     rangeMin={0}
                     rangeMax={45000}
                     rangeStep={1000}
                     handler={e => updateAnnualMiles(e.target.value)}
              />
       </div>
    )
}

export default SelectAnnualMileage
