/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  updateZipcode,
  updateTrims,
} from "../redux/Slices/userSlice";
import { getZipcode, getFullVehicle } from "../redux/selector";
import { useDispatch, useSelector } from "react-redux";
import { useGetVehicleQuery } from "../redux/services/unifiedAPIQuery";
import getUserPref from "../context/UserPrefs/getUserPref";
import { useWidget } from "../context/widgetContext";
import fetchElectricVehicles from "../services/fetchElectricVehicles";
import fetchGasolineVehicles from "../services/fetchGasolineVehicles";

const queryString = require("query-string");

export default function useGetData() {
  const [skip, setSkip] = useState(true);
  const zipcode = useSelector(getZipcode);
  const activeTrim = useSelector(getFullVehicle);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    activeTrim: null,
    vehicleData: null,
    vehicleDataBackup: null,
    zipcode: null,
  });

  const {
    status: vehicleStatus,
    data: vehicleData,
    error: vehicleError,
    isLoading: vehicleLoading,
  } = useGetVehicleQuery(
    {
      handle: activeTrim,
      postcode: zipcode,
    },
    { skip }
  );

  const { userPreferences } = useWidget();

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    let trims = [];
    let activeTrim = [];
    if (Array.isArray(query.trims)) {
      query.trims.map((trim) => trims.push(JSON.parse(trim)));
      activeTrim.push(JSON.parse(query.trims[0]));
    } 
    dispatch(updateZipcode(query?.zipcode));

    if (trims) {
      dispatch(updateTrims(trims));
      setSkip(false);
      setData({...data, activeTrim: activeTrim[0]})
    }
  }, [dispatch, setSkip]);



  useEffect(() => {
    if (vehicleStatus !== "fulfilled") return;
    if(activeTrim) {
      setData({ vehicleData, zipcode, activeTrim });
    } else {
      setData({ vehicleData, zipcode });
    }
    
  }, [vehicleStatus, activeTrim, vehicleData, zipcode]);

  useEffect(() => {
    let paramsEletric = {
      postcode: zipcode,
      household_size: getUserPref('householdSize', userPreferences),
      household_income: getUserPref(
        'householdIncome',
        userPreferences
      ),
      lang: null,
    };

    let paramsGas = {
      postcode: zipcode,
      fuel_type: 'gas',
      lang: null,
    };

    const fetchVehicle = async () => {
      const electricVehicles = await fetchElectricVehicles(paramsEletric);
      const gasolineVehicles = await fetchGasolineVehicles(paramsGas);
      if(electricVehicles && gasolineVehicles)  setData({ vehicleDataBackup: electricVehicles, gasolineVehicles: gasolineVehicles });
    }

    fetchVehicle()
    
  },[])

  return {
    isLoading: vehicleLoading,
    isError: Boolean(vehicleError),
    error: vehicleError,
    vehicleData: vehicleData || {...data?.vehicleDataBackup?.newElectricVehicles, ...data?.vehicleDataBackup?.usedElectricVehicles },
    usedVehicle: data?.vehicleDataBackup?.usedElectricVehicles,
    newVehicle: data?.vehicleDataBackup?.newElectricVehicles,
    gasolineVehicles: data?.gasolineVehicles,
    activeTrim: data?.activeTrim,
    zipcode: zipcode || getUserPref('zipcode', userPreferences),
    ...data,
  };
}
