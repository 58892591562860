/* eslint-disable react-hooks/exhaustive-deps */
import "react-app-polyfill/ie11";
import React, { useState, useEffect, useCallback } from "react";

import { IntlProvider } from "react-intl";
import "./utils/polyfills";
import messages_en from "./client_customizations/translations/en.js";
import messages_esp from "./client_customizations/translations/esp.js";
import { gql, GraphQLClient } from "graphql-request";
import Tabs from "./components/Tabs/Tabs.js";
import './App.scss'
import { useWidget } from "./context/widgetContext.js";
import { updateVehicles } from "./redux/Slices/userSlice.js";
import useGetData from "./utils/useGetData.js";
import { useDispatch,useSelector } from "react-redux";
import footer_logo from "../src/client_customizations/assets/images/zappy-jdp-logo.svg";
import queryString from 'query-string';
import { getFullVehicle } from "./redux/selector.js";

const App = () => {
  const [language] = useState("EN");
  const [messages, setMessages] = useState({
    EN: messages_en,
    ES: messages_esp,
  });
  const dispatch = useDispatch()
  const { enabledModules, activeModuleId, electricVehicles } = useWidget();
  const { newVehicle, gasolineVehicles, activeTrim } = useGetData();
  const query = queryString.parse(window.location.search);



  const fullVehicle = useSelector(getFullVehicle)


  useEffect(() => {
    if(query?.vehicle_model && electricVehicles?.length && !fullVehicle?.length) {
      const sortedVehicles = electricVehicles.filter(
        (vehicle) => vehicle.model === query.vehicle_model
      );

      dispatch(updateVehicles(sortedVehicles[0]?.handle))
    }
  }, [electricVehicles]);


  useEffect(() => {
    const fetchTranslations = async (token) => {
      let endpoint = process.env.REACT_APP_AMPLIFY_STAGING_ENDPOINT;
      let apiKey = process.env.REACT_APP_AMPLIFY_STAGING_API_KEY;

      if (process.env.REACT_APP_ENVIRONMENT === "production") {
        endpoint = process.env.REACT_APP_AMPLIFY_PRODUCTION_ENDPOINT;
        apiKey = process.env.REACT_APP_AMPLIFY_PRODUCTION_API_KEY;
      }

      const graphQLClient = new GraphQLClient(endpoint, {
        headers: {
          "X-Api-Key": apiKey,
        },
      });

      let query = gql`
        {
          listEVShopperTranslations {
            items {
              web_id
              english
              spanish
            }
            nextToken
          }
        }
      `;

      if (token) {
        query = gql`
          {
            listEVShopperTranslations(nextToken: "${token}") {
              items {
              web_id
              english
              spanish
              }
              nextToken
            }
          }
        `;
      }

      const data = await graphQLClient.request(query);
      return data;
    };

    const setTranslations = async () => {
      const engTranslations = {};
      const esTranslations = {};
      let token;

      while (token !== null) {
        let data = await fetchTranslations(token);
        data.listEVShopperTranslations.items.forEach((translation) => {
          engTranslations[translation.web_id] = translation.english;
          esTranslations[translation.web_id] = translation.spanish;
        });
        token = data.listEVShopperTranslations.nextToken;
      }

      setMessages((prevState) => {
        return {
          ...prevState,
          EN: { ...prevState["EN"], ...engTranslations },
          ES: { ...prevState["ES"], ...esTranslations },
        };
      });
    };

    setTranslations();
  }, []);

  const props = {
    electricVehicles: newVehicle,
    gasolineVehicles: gasolineVehicles,
    vehicle: newVehicle ? newVehicle[0] : [],
    activeTrim: activeTrim
  }

  const renderActiveComponent = useCallback(() => {
    const activeModule = enabledModules.find(module => module.id === activeModuleId);
    return activeModule ? React.createElement(activeModule.Component, {...props}) : <div>Select a module</div>;
  }, [activeModuleId, props, enabledModules]);


  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <div className="main-container">
        <header className="title-container">
          <h1>ESTIMATE THE BENEFITS OF DRIVING ELECTRIC</h1>
        </header>
        <body>
          <div className="tabs-container">
            <Tabs />
          </div>
          <main className="content-container">{renderActiveComponent()}</main>
          <footer className="footer-container">
            <div className="footer-content container">
              <img src={footer_logo} alt="Zappyride JD Power Logo" />
              <p>
                Figures shown on this page are estimates based on data provided
                by third-parties. This material is provided for informational
                purposes only and should not be relied on for tax, legal, or
                financial advise. J.D. Power® and Lexus make no representations
                or warranties as to the accuracy or completeness of the
                information and disclaims all liability therefor. Customers
                should consult with their own tax and legal professionals to
                determine their eligibility and confirm the incentives and
                benefits associated with a vehicle before making a purchasing
                decision.
              </p>
            </div>
          </footer>
        </body>
      </div>
    </IntlProvider>
  );
};

export default App;
