import React, {useEffect} from "react";

import Range from "../../../components/shared/InputElements/Range";
import ToolTip from '../../../components/shared/ToolTip/ToolTip';
import { getActiveVehicle, getPercentElectric } from '../../../redux/selector';
import { useDispatch, useSelector } from 'react-redux';
import { updatePercentElectric } from "../../../redux/Slices/userSlice";
import { FormatAsPercentRounded } from "../../../utils/Helpers/Format";

const descriptionFn = val => {
       return FormatAsPercentRounded(val);
};

const SlidePercentElectric = ({
       description = descriptionFn
}) => {
    const dispatch = useDispatch()
    const percentElectric = useSelector(getPercentElectric)
    const isRZ = useSelector(getActiveVehicle) === 'RZ';
    const maxNumber = 100;

    const updateElectricPercentage = (percent) => {
        if (!isNaN(Number(percent))) {
            if (percent > maxNumber) {
                dispatch(updatePercentElectric(maxNumber))
            } else {
                dispatch(updatePercentElectric(Number(percent)))
            }
        }
    }
    useEffect(() => {
      if (isRZ) {
        updateElectricPercentage(100);
      } else {
        updateElectricPercentage(70);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRZ]);

    return (
      <div className="input-group-container">
        <div className="incentive-label-select">
          <label htmlFor="annualMileage">ELECTRIC MILES DRIVEN</label>
          <ToolTip
            message={
              'Estimate what percentage of your driving miles will be under pure electric power.'
            }
            id={'slide-percent-electric-tooltip'}
          />
        </div>
        <Range
          value={percentElectric}
          description={description}
          ariaLabel="Annual Mileage"
          rangeMin={0}
          rangeMax={100}
          rangeStep={10}
          handler={(e) => updateElectricPercentage(e.target.value)}
          disabled={isRZ}
        />
      </div>
    );
}

export default SlidePercentElectric
