import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import unique from "lodash/uniq";

import Select from "../../../../components/shared/InputElements/Select";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTrim,getActiveVehicle } from "../../../../redux/selector";

import { updateActiveTrim, updateVehicles } from "../../../../redux/Slices/userSlice";

const SelectVehicleTrimFilter = ({
  id = "select-vehicle-trim-filter",
  vehicles,
  ...rest
}) => {
  const intl = useIntl();
  const label = intl.formatMessage
    ? intl.formatMessage({ id: "trim", defaultMessage: "TRIM" })
    : "TRIM";

  const dispatch = useDispatch()


  const makeOption = "Lexus"
  const trim = useSelector(getActiveTrim)
  const modelOption = useSelector(getActiveVehicle)
  const previousModelOption = useRef(modelOption);
  
  const vehicleTrimOptions = (vehicles, makeOption, modelOption) => {
    if (modelOption && makeOption) {
      vehicles = vehicles.filter((vehicle) => {
        return vehicle.model === modelOption;
      });
      vehicles = vehicles.filter((vehicle) => {
        return vehicle.make === makeOption;
      });
      vehicles.sort((a, b) => {
        return a.trim.localeCompare(b.trim);
      });

      return unique(
        (vehicles || []).map((ev) => ev.trim).filter((trim) => trim.length > 0)
      );
    }
  };

  const updateTrim = (trim) => {
    dispatch(updateActiveTrim(trim))
  }

  useEffect(() => {
    if(trim && modelOption && vehicles.length) {
      const sortedVehicles = vehicles.filter(
        (vehicle) => (vehicle.trim === trim) && (vehicle.model === modelOption)
      );
      dispatch(updateVehicles(sortedVehicles[0]?.handle))
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[trim, vehicles])

 useEffect(() => {
   if (trim && previousModelOption.current !== modelOption) {
     updateTrim('');
   }

   previousModelOption.current = modelOption;
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [modelOption]);


  return (
    <Select
      id={id}
      value={trim}
      label={label}
      optionNames={[
        intl.formatMessage
          ? intl.formatMessage({
              id: "incentives.selectTrim",
              defaultMessage: "Select Trim",
            })
          : "Select Trim",
        ...(vehicleTrimOptions(vehicles, makeOption, modelOption) || []),
      ]}
      optionValues={[
        "",
        ...(vehicleTrimOptions(vehicles, makeOption, modelOption) || []),
      ]}
      disabled={
        modelOption &&
        vehicleTrimOptions(vehicles, makeOption, modelOption).length > 0
          ? false
          : true
      }
      handler={(e) => updateTrim(e.target.value)}
    />
  );
};

export default SelectVehicleTrimFilter;

SelectVehicleTrimFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
